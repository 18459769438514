import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";

import Layout from "../components/layout";
import SEO from "../components/MetaSeo";
import MiniHero from "../components/MiniHero";
import Card from "../components/Card";

const ResultPage = ({ data, pageContext: { tag } }) => {
  const posts = data.allWordpressPost.edges.map(({ node }) => node);
  return (
    <Layout>
      <SEO
        keywords={[tag.name]}
        title={`Tudo sobre ${tag.name} | Dicas de alimentação, higiene e comportamento`}
        image={get(posts, "[0].featured_media.source_url", null)}
        pathname={`tag/${tag.slug}`}
      />

      <MiniHero
        type="tag"
        name={tag.name}
        description={tag.description}
        tag
        pattern="hero-pattern-diamante"
      />

    
      <div className="container my-4">
        <div className="text-center flex justify-center my-4">
          <a
            href="https://alertapromo.com/promocoes/exclusivas?utm_source=profregadas&utm_content=rect_ad"
            target="_blank"
            rel="noreferrer"
          >
            <img src={`/banner-728-90-exclusivas.png`} alt="alertapromo.com" />
          </a>
        </div>

        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap -m-4">
            {posts.map((node) => (
              <Card key={node.slug} {...node} />
            ))}
          </div>
        </div>
      </div>

    </Layout>
  );
};
// wordpress_id
export const pageQuery = graphql`
  query($slug: String) {
    allWordpressPost(
      limit: 24
      filter: { tags: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      edges {
        node {
          date(formatString: "YYYY/MM/DD")
          title
          slug
          author {
            name
          }
          tags {
            name
            slug
            path
          }
          categories {
            name
            slug
            path
          }
          featured_media {
            source_url
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ResultPage;
